import Image from "components/image";
import TOC from "components/toc";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import List from "components/list";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import Twenty from "react-twentytwenty";
import * as React from 'react';
export default {
  Image,
  TOC,
  Socials,
  SmallBox,
  AccentBox,
  BasicTable,
  List,
  Link,
  graphql,
  Card,
  Twenty,
  React
};