import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import Card from "components/card";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import SmallBox from "components/smallBox";
import PassMarkSearch from 'components/passMarkSearch';
import * as React from 'react';
export default {
  Image,
  TOC,
  SaleLoadable,
  Socials,
  AccentBox,
  Card,
  Link,
  graphql,
  BasicTable,
  SmallBox,
  PassMarkSearch,
  React
};