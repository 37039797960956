// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-aboutme-mdx": () => import("./../../../src/pages/aboutme.mdx" /* webpackChunkName: "component---src-pages-aboutme-mdx" */),
  "component---src-pages-beginner-mdx": () => import("./../../../src/pages/beginner.mdx" /* webpackChunkName: "component---src-pages-beginner-mdx" */),
  "component---src-pages-brands-mdx": () => import("./../../../src/pages/brands.mdx" /* webpackChunkName: "component---src-pages-brands-mdx" */),
  "component---src-pages-business-mdx": () => import("./../../../src/pages/business.mdx" /* webpackChunkName: "component---src-pages-business-mdx" */),
  "component---src-pages-category-cpu-js": () => import("./../../../src/pages/category/cpu.js" /* webpackChunkName: "component---src-pages-category-cpu-js" */),
  "component---src-pages-category-gpu-js": () => import("./../../../src/pages/category/gpu.js" /* webpackChunkName: "component---src-pages-category-gpu-js" */),
  "component---src-pages-category-pcparts-js": () => import("./../../../src/pages/category/pcparts.js" /* webpackChunkName: "component---src-pages-category-pcparts-js" */),
  "component---src-pages-category-pcselection-js": () => import("./../../../src/pages/category/pcselection.js" /* webpackChunkName: "component---src-pages-category-pcselection-js" */),
  "component---src-pages-category-rank-js": () => import("./../../../src/pages/category/rank.js" /* webpackChunkName: "component---src-pages-category-rank-js" */),
  "component---src-pages-cpu-apple-mdx": () => import("./../../../src/pages/cpu/apple.mdx" /* webpackChunkName: "component---src-pages-cpu-apple-mdx" */),
  "component---src-pages-cpu-celeron-mdx": () => import("./../../../src/pages/cpu/celeron.mdx" /* webpackChunkName: "component---src-pages-cpu-celeron-mdx" */),
  "component---src-pages-cpu-cinebench-2024-mdx": () => import("./../../../src/pages/cpu/cinebench2024.mdx" /* webpackChunkName: "component---src-pages-cpu-cinebench-2024-mdx" */),
  "component---src-pages-cpu-cinebench-r-23-mdx": () => import("./../../../src/pages/cpu/cinebench-r23.mdx" /* webpackChunkName: "component---src-pages-cpu-cinebench-r-23-mdx" */),
  "component---src-pages-cpu-core-i-3-mdx": () => import("./../../../src/pages/cpu/core-i3.mdx" /* webpackChunkName: "component---src-pages-cpu-core-i-3-mdx" */),
  "component---src-pages-cpu-core-i-5-mdx": () => import("./../../../src/pages/cpu/core-i5.mdx" /* webpackChunkName: "component---src-pages-cpu-core-i-5-mdx" */),
  "component---src-pages-cpu-core-i-7-mdx": () => import("./../../../src/pages/cpu/core-i7.mdx" /* webpackChunkName: "component---src-pages-cpu-core-i-7-mdx" */),
  "component---src-pages-cpu-core-i-9-mdx": () => import("./../../../src/pages/cpu/core-i9.mdx" /* webpackChunkName: "component---src-pages-cpu-core-i-9-mdx" */),
  "component---src-pages-cpu-core-i-mdx": () => import("./../../../src/pages/cpu/core-i.mdx" /* webpackChunkName: "component---src-pages-cpu-core-i-mdx" */),
  "component---src-pages-cpu-cospa-mdx": () => import("./../../../src/pages/cpu/cospa.mdx" /* webpackChunkName: "component---src-pages-cpu-cospa-mdx" */),
  "component---src-pages-cpu-extreme-mdx": () => import("./../../../src/pages/cpu/extreme.mdx" /* webpackChunkName: "component---src-pages-cpu-extreme-mdx" */),
  "component---src-pages-cpu-gaming-cpu-mdx": () => import("./../../../src/pages/cpu/gaming-cpu.mdx" /* webpackChunkName: "component---src-pages-cpu-gaming-cpu-mdx" */),
  "component---src-pages-cpu-mdx": () => import("./../../../src/pages/cpu.mdx" /* webpackChunkName: "component---src-pages-cpu-mdx" */),
  "component---src-pages-cpu-notepc-mdx": () => import("./../../../src/pages/cpu/notepc.mdx" /* webpackChunkName: "component---src-pages-cpu-notepc-mdx" */),
  "component---src-pages-cpu-ryzen-intel-mdx": () => import("./../../../src/pages/cpu/ryzen-intel.mdx" /* webpackChunkName: "component---src-pages-cpu-ryzen-intel-mdx" */),
  "component---src-pages-cpu-ryzen-mdx": () => import("./../../../src/pages/cpu/ryzen.mdx" /* webpackChunkName: "component---src-pages-cpu-ryzen-mdx" */),
  "component---src-pages-cpu-select-mdx": () => import("./../../../src/pages/cpu-select.mdx" /* webpackChunkName: "component---src-pages-cpu-select-mdx" */),
  "component---src-pages-daigakusei-mdx": () => import("./../../../src/pages/daigakusei.mdx" /* webpackChunkName: "component---src-pages-daigakusei-mdx" */),
  "component---src-pages-desktop-maker-mdx": () => import("./../../../src/pages/desktop-maker.mdx" /* webpackChunkName: "component---src-pages-desktop-maker-mdx" */),
  "component---src-pages-drive-mdx": () => import("./../../../src/pages/drive.mdx" /* webpackChunkName: "component---src-pages-drive-mdx" */),
  "component---src-pages-editvideo-mdx": () => import("./../../../src/pages/editvideo.mdx" /* webpackChunkName: "component---src-pages-editvideo-mdx" */),
  "component---src-pages-electricbill-mdx": () => import("./../../../src/pages/electricbill.mdx" /* webpackChunkName: "component---src-pages-electricbill-mdx" */),
  "component---src-pages-extention-mdx": () => import("./../../../src/pages/extention.mdx" /* webpackChunkName: "component---src-pages-extention-mdx" */),
  "component---src-pages-fujitsu-mdx": () => import("./../../../src/pages/fujitsu.mdx" /* webpackChunkName: "component---src-pages-fujitsu-mdx" */),
  "component---src-pages-gaming-note-mdx": () => import("./../../../src/pages/gaming-note.mdx" /* webpackChunkName: "component---src-pages-gaming-note-mdx" */),
  "component---src-pages-gamingpc-mdx": () => import("./../../../src/pages/gamingpc.mdx" /* webpackChunkName: "component---src-pages-gamingpc-mdx" */),
  "component---src-pages-gpu-cpu-bottleneck-mdx": () => import("./../../../src/pages/gpu/cpu-bottleneck.mdx" /* webpackChunkName: "component---src-pages-gpu-cpu-bottleneck-mdx" */),
  "component---src-pages-gpu-geforce-mdx": () => import("./../../../src/pages/gpu/geforce.mdx" /* webpackChunkName: "component---src-pages-gpu-geforce-mdx" */),
  "component---src-pages-gpu-geforce-vs-radeon-mdx": () => import("./../../../src/pages/gpu/geforce-vs-radeon.mdx" /* webpackChunkName: "component---src-pages-gpu-geforce-vs-radeon-mdx" */),
  "component---src-pages-gpu-gpu-cospa-mdx": () => import("./../../../src/pages/gpu/gpu-cospa.mdx" /* webpackChunkName: "component---src-pages-gpu-gpu-cospa-mdx" */),
  "component---src-pages-gpu-mdx": () => import("./../../../src/pages/gpu.mdx" /* webpackChunkName: "component---src-pages-gpu-mdx" */),
  "component---src-pages-gpu-radeon-mdx": () => import("./../../../src/pages/gpu/radeon.mdx" /* webpackChunkName: "component---src-pages-gpu-radeon-mdx" */),
  "component---src-pages-gpu-rtx-3060-mdx": () => import("./../../../src/pages/gpu/rtx3060.mdx" /* webpackChunkName: "component---src-pages-gpu-rtx-3060-mdx" */),
  "component---src-pages-grabo-mdx": () => import("./../../../src/pages/grabo.mdx" /* webpackChunkName: "component---src-pages-grabo-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-keyboard-mdx": () => import("./../../../src/pages/keyboard.mdx" /* webpackChunkName: "component---src-pages-keyboard-mdx" */),
  "component---src-pages-makers-dell-mdx": () => import("./../../../src/pages/makers/dell.mdx" /* webpackChunkName: "component---src-pages-makers-dell-mdx" */),
  "component---src-pages-makers-fujitsu-mdx": () => import("./../../../src/pages/makers/fujitsu.mdx" /* webpackChunkName: "component---src-pages-makers-fujitsu-mdx" */),
  "component---src-pages-makers-lenovo-mdx": () => import("./../../../src/pages/makers/lenovo.mdx" /* webpackChunkName: "component---src-pages-makers-lenovo-mdx" */),
  "component---src-pages-makers-mouse-computer-mdx": () => import("./../../../src/pages/makers/mouse-computer.mdx" /* webpackChunkName: "component---src-pages-makers-mouse-computer-mdx" */),
  "component---src-pages-makers-nec-mdx": () => import("./../../../src/pages/makers/nec.mdx" /* webpackChunkName: "component---src-pages-makers-nec-mdx" */),
  "component---src-pages-makers-pckoubou-mdx": () => import("./../../../src/pages/makers/pckoubou.mdx" /* webpackChunkName: "component---src-pages-makers-pckoubou-mdx" */),
  "component---src-pages-memory-expansion-mdx": () => import("./../../../src/pages/memory/expansion.mdx" /* webpackChunkName: "component---src-pages-memory-expansion-mdx" */),
  "component---src-pages-memory-mdx": () => import("./../../../src/pages/memory.mdx" /* webpackChunkName: "component---src-pages-memory-mdx" */),
  "component---src-pages-mobile-12-5-mdx": () => import("./../../../src/pages/mobile12-5.mdx" /* webpackChunkName: "component---src-pages-mobile-12-5-mdx" */),
  "component---src-pages-monitor-4-k-43-inch-mdx": () => import("./../../../src/pages/monitor/4k43inch.mdx" /* webpackChunkName: "component---src-pages-monitor-4-k-43-inch-mdx" */),
  "component---src-pages-monitor-4-kmonitor-mdx": () => import("./../../../src/pages/monitor/4Kmonitor.mdx" /* webpackChunkName: "component---src-pages-monitor-4-kmonitor-mdx" */),
  "component---src-pages-monitor-mdx": () => import("./../../../src/pages/monitor.mdx" /* webpackChunkName: "component---src-pages-monitor-mdx" */),
  "component---src-pages-mouse-mdx": () => import("./../../../src/pages/mouse.mdx" /* webpackChunkName: "component---src-pages-mouse-mdx" */),
  "component---src-pages-note-cospa-mdx": () => import("./../../../src/pages/note-cospa.mdx" /* webpackChunkName: "component---src-pages-note-cospa-mdx" */),
  "component---src-pages-notepc-maker-mdx": () => import("./../../../src/pages/notepc-maker.mdx" /* webpackChunkName: "component---src-pages-notepc-maker-mdx" */),
  "component---src-pages-office-mdx": () => import("./../../../src/pages/office.mdx" /* webpackChunkName: "component---src-pages-office-mdx" */),
  "component---src-pages-pc-sales-mdx": () => import("./../../../src/pages/pc-sales.mdx" /* webpackChunkName: "component---src-pages-pc-sales-mdx" */),
  "component---src-pages-pcmaker-mdx": () => import("./../../../src/pages/pcmaker.mdx" /* webpackChunkName: "component---src-pages-pcmaker-mdx" */),
  "component---src-pages-programming-kids-mdx": () => import("./../../../src/pages/programming-kids.mdx" /* webpackChunkName: "component---src-pages-programming-kids-mdx" */),
  "component---src-pages-programming-mdx": () => import("./../../../src/pages/programming.mdx" /* webpackChunkName: "component---src-pages-programming-mdx" */),
  "component---src-pages-ssd-mdx": () => import("./../../../src/pages/ssd.mdx" /* webpackChunkName: "component---src-pages-ssd-mdx" */),
  "component---src-pages-storage-mdx": () => import("./../../../src/pages/storage.mdx" /* webpackChunkName: "component---src-pages-storage-mdx" */),
  "component---src-pages-storage-ssd-lifespan-mdx": () => import("./../../../src/pages/storage/ssd-lifespan.mdx" /* webpackChunkName: "component---src-pages-storage-ssd-lifespan-mdx" */),
  "component---src-pages-storage-ssd-nvme-ahci-m-2-pcie-sata-mdx": () => import("./../../../src/pages/storage/ssd_nvme_ahci_m2_pcie_sata.mdx" /* webpackChunkName: "component---src-pages-storage-ssd-nvme-ahci-m-2-pcie-sata-mdx" */),
  "component---src-pages-storage-sshd-mdx": () => import("./../../../src/pages/storage/sshd.mdx" /* webpackChunkName: "component---src-pages-storage-sshd-mdx" */),
  "component---src-pages-usb-type-c-mdx": () => import("./../../../src/pages/usb_type-c.mdx" /* webpackChunkName: "component---src-pages-usb-type-c-mdx" */),
  "component---src-pages-used-mdx": () => import("./../../../src/pages/used.mdx" /* webpackChunkName: "component---src-pages-used-mdx" */),
  "component---src-pages-used-pc-mdx": () => import("./../../../src/pages/used-pc.mdx" /* webpackChunkName: "component---src-pages-used-pc-mdx" */),
  "component---src-pages-used-select-mdx": () => import("./../../../src/pages/used-select.mdx" /* webpackChunkName: "component---src-pages-used-select-mdx" */),
  "component---src-pages-used-shop-mdx": () => import("./../../../src/pages/used-shop.mdx" /* webpackChunkName: "component---src-pages-used-shop-mdx" */),
  "component---src-pages-virus-mdx": () => import("./../../../src/pages/virus.mdx" /* webpackChunkName: "component---src-pages-virus-mdx" */),
  "component---src-pages-where-mdx": () => import("./../../../src/pages/where.mdx" /* webpackChunkName: "component---src-pages-where-mdx" */),
  "component---src-pages-which-mdx": () => import("./../../../src/pages/which.mdx" /* webpackChunkName: "component---src-pages-which-mdx" */),
  "component---src-pages-wireless-mdx": () => import("./../../../src/pages/wireless.mdx" /* webpackChunkName: "component---src-pages-wireless-mdx" */),
  "component---src-pages-zaitaku-mdx": () => import("./../../../src/pages/zaitaku.mdx" /* webpackChunkName: "component---src-pages-zaitaku-mdx" */)
}

